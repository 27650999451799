const emailRegex = /([-!# - '*+/-9=?A-Z^-~]+(\.[-!#-' * +/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/

const email = {
    fn: (email) => {
        return emailRegex.test(email)
    },
    message: () => {
        return `Veld moet een E-Mailadres zijn`
    }
}

const required = {
    fn: (required) => {
        return !!required
    },
    message: () => {
        return `Veld is verplicht`
    }
}

function maxLength(length) {
    return {
        fn: (value) => {
            return !value ? true : value?.length <= length
        },
        message: () => {
            return `Maximale lengte van ${length} karakters`
        }
    }
}

function minLength(length) {
    return {
        fn: (value) => {
            return !value ? true : value?.length >= length
        },
        message: () => {
            return `Minimale lengte van ${length} karakters`
        }
    }
}

function fixedLength(length) {
    return {
        fn: (value) => {
            return !value ? true : value?.length === length
        },
        message: () => {
            return `Veld moet ${length} karakters lang zijn`
        }
    }
}

function biggerThen(length) {
    return {
        fn: (min) => {
            return min > length
        },
        message: () => {
            return `Veld moet groter zijn dan ${length}`
        }
    }
}

const isLicensePlate = {
    fn: (licensePlate) => {
        if (licensePlate === null || licensePlate === '') return true
        licensePlate = licensePlate.replaceAll('-', '')
        let valid = false
        let arrSC = []

        arrSC[0] = /^[a-zA-Z]{2}[0-9]{2}[0-9]{2}$/
        arrSC[1] = /^[0-9]{2}[0-9]{2}[a-zA-Z]{2}$/
        arrSC[2] = /^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$/
        arrSC[3] = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}$/
        arrSC[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[0-9]{2}$/
        arrSC[5] = /^[0-9]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/
        arrSC[6] = /^[0-9]{2}[a-zA-Z]{3}[0-9]{1}$/
        arrSC[7] = /^[0-9]{1}[a-zA-Z]{3}[0-9]{2}$/
        arrSC[8] = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{1}$/
        arrSC[9] = /^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{2}$/
        arrSC[10] = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}$/
        arrSC[11] = /^[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{3}$/
        arrSC[12] = /^[0-9]{1}[a-zA-Z]{2}[0-9]{3}$/
        arrSC[13] = /^[0-9]{3}[a-zA-Z]{2}[0-9]{1}$/

        arrSC.forEach((validation) => {
            if (licensePlate.match(validation)) {
                valid = true
            }
        })

        return valid
    },
    message: () => {
        return `Veld moet een geldig kenteken bevatten`
    }
}

export {
    biggerThen,
    email,
    fixedLength,
    isLicensePlate,
    maxLength,
    minLength,
    required
}
