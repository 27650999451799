<template>
  <div v-if="form">
    <v-input v-model="form.make"
             label="Merk"
             place-holder-text="Voorbeeld (Audi)"
             :disabled="disabled"
             :show-errors="showErrors"/>
    <v-validate-button :disabled="disabled"
                       :form="form"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import validations from "@/components/forms/make/make.validator";
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  props: {
    make: {
    }
  },
  created() {
    this.form = validations
    this.form.make.value = this.make.name
  }
}
</script>
