<script>
export default {
  name: 'vModal',
  data() {
    return {
      open: false,
    }
  },
  methods: {
    closeHandler() {
      this.open = false
    }
  }
}
</script>
