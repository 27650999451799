<template>
  <d-modal v-if="open" @close="closeHandler">
    <d-modal-header>
      <d-modal-title>
        Merk wijzigen
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <editMake :make="make" ref="editMake"/>
    </d-modal-body>
  </d-modal>
</template>


<script>
import vModal from './vModal'
import editMake from '@/components/forms/make/editMake'

export default {
  extends: vModal,
  components: {
    editMake
  },
  data() {
    return {
      make: null
    }
  },
  methods: {
    openHandler(make) {
      this.open = !this.open
      console.log(make)
    },
    closeHandler() {
      this.open = false
    }
  }
}
</script>
