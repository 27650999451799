<template>
  <div>
    <form-make @valid="eventValidForm" :make="make" ref="makeForm" :disabled="false"/>
  </div>
</template>

<script>
import formMake from './formMake'

export default {
  name: 'addAddress',
  components: {
    formMake
  },
  props: {
    make: {
      required: true
    }
  },
  data() {
    return {
      isDisabled: false
    }
  },
  methods: {
    async eventValidForm({make}) {
     console.log(make)
    }
  }
}
</script>
